.message-enter {
    opacity: 0;
    transform: translateY(20px);
}

.message-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 500ms, transform 500ms;
}

.message-exit {
    opacity: 1;
    transform: translateY(0);
}

.message-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 500ms, transform 500ms;
}

.message {
    display: flex;
    align-items: flex-end;
    transform-origin: 0 100%;
    padding-top: 0;
    transform: scale(0);
    max-height: 0;
    overflow: hidden;
    animation: message 0.25s ease-out 0s forwards;
    animation-delay: var(--timeline);
}

.message_B {
    flex-direction: row-reverse;
    text-align: right;
    align-self: flex-end;
    transform-origin: 100% 100%;
}

@keyframes message {
    0% {
        max-height: 100vmax;
    }

    80% {
        transform: scale(1);
    }

    100% {
        transform: scale(1);
        max-height: 100vmax;
        overflow: visible;
    }
}