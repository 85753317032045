@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Regular.woff2') format('woff2'),
        url('ProximaNova-Regular.woff') format('woff'),
        url('ProximaNova-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNovaT-Thin.woff2') format('woff2'),
        url('ProximaNovaT-Thin.woff') format('woff'),
        url('ProximaNovaT-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Black.woff2') format('woff2'),
        url('ProximaNova-Black.woff') format('woff'),
        url('ProximaNova-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Extrabld.woff2') format('woff2'),
        url('ProximaNova-Extrabld.woff') format('woff'),
        url('ProximaNova-Extrabld.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Semibold.woff2') format('woff2'),
        url('ProximaNova-Semibold.woff') format('woff'),
        url('ProximaNova-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Proxima Nova';
    src: url('ProximaNova-Bold.woff2') format('woff2'),
        url('ProximaNova-Bold.woff') format('woff'),
        url('ProximaNova-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

