@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap");
@import "./assets/font/font.css";
@import "./assets/css/Chat.css";

@layer base {
  :root {
    --novel-highlight-default: #ffffff;
    --novel-highlight-purple: #f6f3f8;
    --novel-highlight-red: #fdebeb;
    --novel-highlight-yellow: #fbf4a2;
    --novel-highlight-blue: #c1ecf9;
    --novel-highlight-green: #acf79f;
    --novel-highlight-orange: #faebdd;
    --novel-highlight-pink: #faf1f5;
    --novel-highlight-gray: #f1f1ef;
  }

  .dark {
    --novel-highlight-default: #000000;
    --novel-highlight-purple: #3f2c4b;
    --novel-highlight-red: #5c1a1a;
    --novel-highlight-yellow: #5c4b1a;
    --novel-highlight-blue: #1a3d5c;
    --novel-highlight-green: #1a5c20;
    --novel-highlight-orange: #5c3a1a;
    --novel-highlight-pink: #5c1a3a;
    --novel-highlight-gray: #3a3a3a;
  }
}

* {
  font-family: "Proxima Nova" !important;
}

.App {
  /* text-align: center; */
}

input[type="date"] {
  font-family: "Proxima Nova" !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

::-webkit-scrollbar {
  @apply w-1 h-1 bg-primary;
}

::-webkit-scrollbar-track {
  @apply w-1 h-1 bg-forground;
}

::-webkit-scrollbar-thumb {
  @apply w-1 h-1 bg-primary/20 rounded-md;
}

.hidden-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custome_date .react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-wrapper input {
  outline: none !important;
  border: none !important;
  background-color: transparent !important;
}

.react-datepicker__header {
  background-color: #d4d6f2 !important;
  border: 1px solid #e3e3e3 !important;
  color: #5461d6 !important;
}

.react-datepicker {
  border: 1px solid #e3e3e3 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #5461d6 !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #000000 !important;
  height: 8px !important;
  border-width: 2px 2px 0 0 !important;
  top: 9px !important;
  width: 8px !important;
}

.week-picker-container {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: transparent;
  padding: 0 15px;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
  width: 190px;
  text-align: left;
  position: relative;
  z-index: 10;
}

.week-picker-label {
  flex-grow: 1;
}

.week-picker-icon {
  margin-left: 10px;
  color: #999;
  font-size: 14px;
}

.datepicker-container {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1000;
  background: white;
  border-radius: 5px;
}

.hover-highlight {
  background-color: #dfe6e9 !important;
  color: black !important;
  border-radius: 50%;
}

.react-datepicker__day--highlighted,
.react-datepicker__day--selected,
.hover-highlight.selected-week {
  background-color: #6c5ce7 !important;
  color: white !important;
  border-radius: 50%;
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }
}

pre {
  background: #e0e0e0;
  border-radius: 0.5rem;
  color: #272727;
  font-family: "JetBrains Mono", monospace !important;
  padding: 0.75rem 1rem;
  font-weight: 500;
  margin-block: 12px;
}

pre code {
  background: none;
  color: inherit;
  font-size: 0.8rem;
  padding: 0;
}

pre .hljs-comment,
pre .hljs-quote {
  color: #272727;
}

pre .hljs-variable,
pre .hljs-template-variable,
pre .hljs-attribute,
pre .hljs-tag,
pre .hljs-name,
pre .hljs-regexp,
pre .hljs-link,
pre .hljs-name,
pre .hljs-selector-id,
pre .hljs-selector-class {
  color: #272727;
}

pre .hljs-number,
pre .hljs-meta,
pre .hljs-built_in,
pre .hljs-builtin-name,
pre .hljs-literal,
pre .hljs-type,
pre .hljs-params {
  color: #272727;
}

pre .hljs-string,
pre .hljs-symbol,
pre .hljs-bullet {
  color: #272727;
}

pre .hljs-title,
pre .hljs-section {
  color: #272727;
}

pre .hljs-keyword,
pre .hljs-selector-tag {
  color: #272727;
}

pre .hljs-emphasis {
  font-style: italic;
}

pre .hljs-strong {
  font-weight: 700;
}

.timesheet-chart {
  .apexcharts-xaxis {
    transform: translateX(8px);
  }
}

.loader {
  width: 60px;
  height: 60px;
  border: 3px dotted #5461d6;
  border-style: solid solid dotted dotted;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 2s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px dotted #009444;
  border-style: solid solid dotted;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  animation: rotationBack 1s linear infinite;
  transform-origin: center center;
}
    
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
} 
    
.banner-bg {
  background-image: url("./assets/images/welcome_banner.png");
  background-repeat: no-repeat;
  background-position: right bottom -1px;
  background-size: 200px;
  @media (max-width: 992px) {
    background-size: 180px;
  }
  @media (max-width: 576px) {
    background-size: 150px;
  }
}

.apexcharts-pie path,
.apexcharts-pie:hover path {
  stroke-width: 0 !important;
  stroke: none !important;
}

.monthly_chart{
  min-height: fit-content !important;
}

.bloone_lottie svg{
  width: 100% !important;
  height: 100% !important;
  transform: scale(3) !important;
}

.status_chart{
  width: 100%;
  min-height: unset !important;
}

.hidden-scrollbar::-webkit-scrollbar {
  @apply hidden;
}